<script>
import { certificates } from "@/config/api/certificates";
import { getFormData } from "@/utils/createForm";
export default {
  props: ["selectedCertificate", "property_types"],
  data() {
    return {
      values: {},
      image: null,
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name EN",
          validation: "required",
        },
        {
          index: 0,
          type: "text",
          name: "name_ar",
          label: "Name AR",
          validation: "required",
        },
        {
          index: 1,
          type: "text",
          name: "abbreviation",
          label: "Abbreviation",
          validation: "max:5",
        },
        {
          index: 2,
          type: "select",
          name: "property_type",
          label: "Architecture Style",
          options: this.property_types.map((val) => {
            return {
              value: val._id,
              label: val.name,
            };
          }),
        },
      ],
    };
  },
  methods: {
    resetModal() {
      this.image = null;
      this.$formulate.reset("CertificateAction", {});
      this.$emit("resetModal");
    },
    async submitHandler(data) {
      if (this.selectedCertificate) {
        if (data.Image) {
          data.image = data.Image[0].location;
        }
        this.updateCertificate(data, this.selectedCertificate._id);
      } else {
        const formdata = getFormData({
          file: this.image.files[0].file,
          name: data.name,
          name_ar: data.name_ar,
          abbreviation: data.abbreviation.trim().toUpperCase(),
          property_type : data.property_type
        });
        this.addCertificate(formdata);
      }
    },
    addCertificate(data) {
      this.performAction = true;

      const api = certificates.create;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Certificate added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Certificate is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateCertificate(data, id) {
      this.performAction = true;
      const api = certificates.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Certificate updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Certificate is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedCertificate(val) {
      if (val) {
        val.property_type = val.property_type._id;
        this.values = { ...val };
      }
    },
    property_types(val) {
      if (val) {
        this.schema.find((item) => item.index == 2).options = val.map((val) => {
          return {
            value: val._id,
            label: val.name,
          };
        });
      }
    },
  },
};
</script>

<template>
  <b-modal
    id="action-Certificate"
    @hide="resetModal"
    :title="selectedCertificate ? 'Update Certificate' : 'Add Certificate'"
    hide-footer
  >
    <FormulateForm
      name="CertificateAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput
        type="image"
        name="Image"
        v-model="image"
        :uploader="() => {}"
        label="Certificate Logo"
        help="Select a png or jpg to upload."
        :validation="
          selectedCertificate
            ? 'mime:image/jpeg,image/png,image/webp'
            : 'required:mime:image/jpeg,image/png,image/webp'
        "
      />
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedCertificate"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedCertificate"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>


<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>