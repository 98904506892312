<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { certificates } from "@/config/api/certificates";
import CertificateModal from "@/components/certificates/certificate-modal";
import CertificateTypes from "@/views/pages/certificates/certificateTypes";
import { property_types } from "@/config/api/property_types";
/**
 * Advanced table component
 */
export default {
  page: {
    title: "CERTIFICATES",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, CertificateModal, CertificateTypes },
  data() {
    return {
      title: "Certificates",
      breadcrumbs: [
        {
          text: "Certificates",
          active: true,
        },
        {
          text: "",
          //   to: { name: "home" },
        },
      ],
      certificateData: [],
      selectedCertificate: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      property_types: [],
      fields: [
        { key: "certificate", sortable: true },
        { key: "certificate_ar", sortable: true },
        { key: "image", sortable: false },
        { key: "property_type", sortable: false },
        { key: "updatedAt", sortable: true },
        { key: "actions", sortable: false },
        { key: "show_details", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {
    // Set the initial number of items
    this.loadPropertyType();
  },
  methods: {
    updatePage(page) {
      this.loadData(page);
    },
    loadAfterUpdate() {
      this.currentPage = 1;
      this.loadData(this.currentPage);
    },
    async loadData(page = 1, search = "") {
      this.isBusy = true;
      const api = certificates.get;
      api.params = { page: page, search: search, limit: this.perPage };
      await this.generateAPI(api)
        .then((res) => {
          res.data.certificates.docs.map((i) => {
            i["isActive"] = true;
          });
          this.certificateData = res.data.certificates.docs;
          this.totalRows = res.data.certificates.totalDocs;
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    editCertificate(data) {
      this.selectedCertificate = data;
      this.$bvModal.show("action-Certificate");
    },
    deleteCertificate(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = certificates.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Certificate deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast("Certificate is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    currentCertificate(data) {
      return (this.selectedCertificate = data);
    },
    showActionModal() {
      this.selectedCertificate = null;
      this.$bvModal.show("action-Certificate");
    },
    hideActionModal() {
      this.selectedCertificate = null;
      this.$bvModal.hide("action-Certificate");
    },
    perPageChange(val) {
      this.perPage = val;
      this.currentPage = 1;
      this.loadData();
    },
    loadPropertyType() {
      const api = property_types.getAllWithoutPagination;
      this.generateAPI(api)
        .then((res) => {
          this.property_types = res.data.property_types;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button id="addCertificates" @click="showActionModal" pill variant="primary">Add Certificates</b-button>
            <!-- All Certificates -->
            <b-tabs nav-class="nav-tabs-custom" v-model="activeTab" active-nav-item-class="font-weight-bold"
              active-tab-class="font-weight-bold" content-class="mt-3">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Certificates</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select :value="perPage" size="sm" @change="perPageChange" :options="pageOptions">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table v-if="activeTab == 0" :items="certificateData" :fields="fields" responsive="sm"
                    :busy="isBusy" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                      </div>
                    </template>
                    <template #cell(property_type)="row">
                      {{ row.value.name }}
                      <strong v-if="row.item.abbreviation">
                        - {{ row.item.abbreviation }}
                      </strong>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button pill variant="primary" size="sm" @click="row.toggleDetails" class="mr-2">
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>
                    <template #row-details="row">
                      <b-card>
                        <CertificateTypes :currentCertificate="row.item" :certificatesData="certificateData" />
                      </b-card>
                    </template>

                    <template #cell(certificate_ar)="row">
                      {{ row.item.name_ar }}
                    </template>
                    <template #cell(certificate)="row">
                      {{ row.item.name || '-' }}
                      <span v-if="row.item.abbreviation">- <strong> {{ row.item.abbreviation }}</strong></span>
                      <br />
                      <b-badge pill variant="danger" v-if="row.item.deletedAt">Deleted</b-badge>
                    </template>
                    <template #cell(image)="row">
                      <a :href="row.item.image" target="_blank">
                        <img :src="row.item.image" style="
                            height: 50px;
                            width: 50px;
                            object-fit: cover;
                            cursor: pointer;
                          " /></a>
                    </template>
                    <template #cell(actions)="row">
                      <button class="action-btn" @click="deleteCertificate(row.item)">
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button class="action-btn" @click="editCertificate(row.item)">
                        <i class="ri-edit-line"></i>
                      </button>
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(updatedAt)="row">
                      {{ moment(row.item.updatedAt).format("l") }}
                    </template>
                    <template #cell(deletedAt)="row">
                      {{
                          row.item.deletedAt !== null
                            ? moment(row.item.deletedAt).format("l")
                            : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                          row.item.deletedBy !== null
                            ? row.item.deletedBy.name
                            : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <CertificateModal :selectedCertificate="selectedCertificate" :property_types="property_types"
      v-on:resetModal="hideActionModal" v-on:reloadData="loadAfterUpdate" v-on:closeModal="hideActionModal" />
  </Layout>
</template>

<style scoped>
#addCertificates {
  float: right;
  margin-top: 10px;
}
</style>